const formulas = {
  ls: {
    value: 'ls',
    title: 'booking.formula.ls.title',
    duration: 'booking.formula.ls.duration',
    subtitle: 'booking.formula.ls.subtitle',
    instructions: 'booking.formula.ls.instructions',
    instructionsLayout: '',
    steps: [
      'booking.formula.ls.bullets.1',
      'booking.formula.ls.bullets.2',
      'booking.formula.ls.bullets.3',
      'booking.formula.ls.bullets.4',
    ],
    stepsInfo: [
      { title: 'booking.formula.ls.steps.info.1.title', content: 'booking.formula.ls.steps.info.1.content' },
      { title: 'booking.formula.ls.steps.info.2.title', content: 'booking.formula.ls.steps.info.2.content' },
      { title: 'booking.formula.ls.steps.info.3.title', content: 'booking.formula.ls.steps.info.3.content' },
      { title: 'booking.formula.ls.steps.info.4.title', content: 'booking.formula.ls.steps.info.4.content' },
    ],
    bullets: [
      'booking.formula.ls.bullets2.1',
      'booking.formula.ls.bullets2.2',
      'booking.formula.ls.bullets2.3',
      'booking.formula.ls.bullets2.4',
      'booking.formula.ls.bullets2.5',
    ],
    description: 'booking.formula.ls.description',
    descriptionInfo: {
      title: 'booking.formula.ls.descriptionInfo.title', content: 'booking.formula.ls.descriptionInfo.content',
    },
    displayCmsKey: 'displayLongStay',
  },
  ms: {
    value: 'ms',
    title: 'booking.formula.ms.title',
    duration: 'booking.formula.ms.duration',
    subtitle: 'booking.formula.ms.subtitle',
    instructions: 'booking.formula.ms.instructions',
    instructionsLayout: 'booking.formula.ms.instructionsLayout',
    steps: [
      'booking.formula.ms.bullets.1',
    ],
    bullets: [
      'booking.formula.ms.bullets2.1',
      'booking.formula.ms.bullets2.2',
      'booking.formula.ms.bullets2.3',
      'booking.formula.ms.bullets2.4',
      'booking.formula.ms.bullets2.5',
    ],
    description: 'booking.formula.ms.description',
    descriptionInfo: {
      title: 'booking.formula.ms.descriptionInfo.title', content: 'booking.formula.ms.descriptionInfo.content',
    },
    displayCmsKey: 'displayMediumStay',
  },
  day: {
    value: 'day',
    title: 'booking.formula.day.title',
    duration: 'booking.formula.day.duration',
    subtitle: 'booking.formula.day.subtitle',
    instructions: 'booking.formula.day.instructions',
    instructionsLayout: 'booking.formula.ms.instructionsLayout',
    steps: [
      'booking.formula.day.bullets.1',
    ],
    bullets: [
      'booking.formula.day.bullets2.1',
      'booking.formula.day.bullets2.2',
      'booking.formula.day.bullets2.3',
      'booking.formula.day.bullets2.4',
    ],
    description: 'booking.formula.day.description',
    descriptionInfo: {
      title: 'booking.formula.day.descriptionInfo.title', content: 'booking.formula.day.descriptionInfo.content',
    },
    displayCmsKey: 'displayShortStay',
  },
};

export default formulas;
