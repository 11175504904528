import { useMemo } from 'react';

// Libs
import secureLocalStorage from 'react-secure-storage';
import { cloneDeep } from 'lodash';

// Utils
import formulas from '../statics/formulas';

// Hooks
import { useAppContext } from '../store/AppContext';

const useBookingForm = () => {
  const [{ bookingForm, bookingResidence }, stateDispatch] = useAppContext();

  const setFormData = (data) => {
    const current = cloneDeep(secureLocalStorage.getItem('booking'));

    const updated = {
      ...current,
      ...data,
    };

    secureLocalStorage.setItem('booking', updated);
    stateDispatch({ type: 'SET_BOOKING_FORM', payload: updated });
  };

  const setResidenceData = (data) => {
    secureLocalStorage.setItem('bookingResidence', data);
    stateDispatch({ type: 'SET_BOOKING_RESIDENCE', payload: data });
  };

  const setBookingAndResidenceData = ({ booking, residence }) => {
    secureLocalStorage.setItem('booking', booking);
    secureLocalStorage.setItem('bookingResidence', residence);
    stateDispatch({
      type: 'SET_BOOKING_AND_RESIDENCE',
      payload: {
        bookingForm: booking,
        bookingResidence: residence,
      },
    });
  };

  const formulasOptions = useMemo(() => (
    Object.values(formulas)
      .filter((formula) => bookingResidence?.[formula.displayCmsKey])
      .map((formula) => (
        { content: formula, value: formula.value }
      ))
  ), [bookingResidence]);

  const defaultBookingFormState = {
    formula: 'ls',
    apartment: null,
    createdAt: new Date(),
    activeFormula: '',
  };

  return {
    setFormData,
    setResidenceData,
    setBookingAndResidenceData,
    bookingForm,
    bookingResidence,
    formulasOptions,
    defaultBookingFormState,
  };
};

export default useBookingForm;
