import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useRouter, Link } from 'next-translate-routes';
import { useSearchParams } from 'next/navigation';
import { omit } from 'lodash';
import dynamic from 'next/dynamic';

// Utils
import { cn } from 'utils';
// Styles
import styles from './LanguageSelector.module.css';
// Components
import { Picto } from '../Picto/Picto';
// Constants
import { LOCALE_EN, LOCALE_FR } from '../../../src/constants';

const Popover = dynamic(() => import('../Popover/Popover'), { ssr: false });

function LanguageSelector({ slugs, className }) {
  const { t } = useTranslation();
  const {
    locale, pathname,
  } = useRouter();
  const URLSearchParams = useSearchParams();

  return (
    <Popover
      minWidth
      animation="fade"
      placement="bottom-end"
      className="flex-auto-shrink"
      clickInside
      content={(
        <div className="d-flex d-col a-start gap-8 flex-auto-shrink">
          {[LOCALE_FR, LOCALE_EN].map((item) => {
            const hasSlugItem = !!slugs[item];
            const isBooking = pathname.includes('booking');
            const isTranslatable = hasSlugItem || isBooking;

            const searchParams = {};
            URLSearchParams.forEach((value, key) => {
              searchParams[key] = value;
            });

            return (item !== locale ? (
              <Link
                href={{
                  pathname: isTranslatable ? pathname : '',
                  query: isTranslatable
                    ? Object.assign(hasSlugItem && { slug: slugs[item] }, omit(searchParams, 'slug')) : null,
                }}
                locale={item}
                key={item}
                className="p2-desktop-bold font-color-blue"
                disabled={locale === item}
              >
                {t(`header.${item}`)}
              </Link>
            ) : (
              <span key={item} className="p2-desktop-bold font-color-blue">
                {t(`header.${item}`)}
              </span>
            ));
          })}
        </div>
     )}
    >
      <div className={cn([styles.languageSelector, className])}>
        <span className="p2-desktop-bold font-color-black">
          {t(`header.${locale}`)}
        </span>
        <Picto icon="chevronDown" className={styles.languageSelectorIcon} />
      </div>
    </Popover>
  );
}

LanguageSelector.propTypes = {
  slugs: PropTypes.shape({}),
  className: PropTypes.string,
};
LanguageSelector.defaultProps = {
  slugs: {},
  className: '',
};

export default LanguageSelector;
