import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { isEmpty, isNull } from 'lodash';
import { Link } from 'next-translate-routes';

// Utils
import { cn } from 'utils';

// Hooks
import useResponsiveQueries from '../../../src/hooks/useResponsiveQueries';
import useBookingForm from '../../../src/hooks/useBookingForm';

// Images
import StaticLogo from '../../../public/images/logo/logo_horizontal.svg';
import StaticMobileLogo from '../../../public/images/logo/logo_bikube.svg';

// Components
import { Picto } from '../../atoms/Picto/Picto';
import BurgerMenu from '../../atoms/BurgerMenu/BurgerMenu';
import Navigation from '../../molecules/Navigation/Navigation';
import LanguageSelector from '../../atoms/LanguageSelector/LanguageSelector';

// Styles
import styles from './Header.module.css';

// Constants
import {
  CONCEPT_SLUG,
  CONTACT_SLUG,
  LOCALE_EN, LOCALE_FR,
  BOOKING_PHONE_NUMBER,
} from '../../../src/constants';

// Statics
import { steps, stepsEnum } from '../../../src/statics/steps';

const contexts = {
  default: 'default',
  booking: 'booking',
};

function Header({
  data: {
    accountLabel,
    accountLink,
    logo,
    menu,
    phone,
    isTranslatable,
  },
  context,
  sticky,
  slugs,
}) {
  const { t } = useTranslation();
  const {
    locale, pathname, events,
  } = useRouter();

  const [menuIsOpen, setMenuIsOpened] = useState(false);

  const { isMobile, isTablet } = useResponsiveQueries();

  const { bookingResidence } = useBookingForm();

  const isHandledDevice = isMobile || isTablet;
  const hideBookingBackToResidence = useMemo(() => {
    // hide back button for tenant step and all steps after
    const currentStep = steps.find((step) => step.path === pathname);
    const tenantStep = steps.find((step) => step.step === stepsEnum.tenant);
    return currentStep && tenantStep && currentStep.num >= tenantStep.num;
  }, [pathname]);

  useEffect(
    () => {
      events.on('routeChangeStart', () => {
        if (menuIsOpen) setMenuIsOpened(false);
      });

      return () => {
        events.off('routeChangeStart', () => {
          if (menuIsOpen) setMenuIsOpened(false);
        });
      };
    },
    [events, menuIsOpen],
  );

  return (
    <>
      <header className={cn([styles.header, sticky ? styles.sticky : ''])}>
        <div className={cn([styles.inner_header, 'd-flex a-center'])}>
          {context === contexts.booking ? (
            <Link href="/" className={styles.staticLogo}>
              {isHandledDevice ? <StaticMobileLogo /> : <StaticLogo />}
            </Link>
          )
            : logo?.url ? (
              <Link href="/" className={styles.logoLink} aria-label={t('header.home')}>
                <div className={styles.logoContainer}>
                  <Image
                    fill
                    src={logo?.url}
                    alt={logo?.description}
                    className={styles.logo}
                    sizes="256px"
                  />
                </div>
              </Link>
            ) : null}

          {context === contexts.booking
            && !isHandledDevice && !hideBookingBackToResidence
            && bookingResidence?.nameLong
            ? (
              <Link
                href={bookingResidence?.originUrl || '/'}
                className="d-flex a-center p2-desktop-bold font-color-black"
                aria-label={t('header.home')}
              >
                <Picto icon="chevronLeft" className="margin-right-8" />
                {bookingResidence?.nameLong}
              </Link>
            ) : null}

          <div
            className={cn([
              'd-flex a-center margin-left-auto',
              !isHandledDevice ? 'padding-left-40 gap-24' : '',
            ])}
          >
            {context === contexts.default && menu?.length ? (
              <Navigation list={menu} type="desktop" />
            ) : null}

            {context === contexts.booking && !isMobile ? (
              <a href={`tel:${BOOKING_PHONE_NUMBER[locale]}`} className={styles.phone} aria-label={`${BOOKING_PHONE_NUMBER[locale]}`}>
                {!isHandledDevice ? (
                  <Picto icon="call" className="margin-right-8" />
                ) : null}
                {BOOKING_PHONE_NUMBER[locale]}
              </a>
            ) : null}

            {context === contexts.default && !isMobile && phone ? (
              <a href={`tel:${phone}`} className={styles.phone} aria-label={phone}>
                {!isMobile ? (
                  <Picto icon="call" className="margin-right-8" />
                ) : null}
                {phone}
              </a>
            ) : null}

            {isTablet ? (
              <BurgerMenu
                isOpened={menuIsOpen}
                setIsOpened={() => setMenuIsOpened(!menuIsOpen)}
                className="margin-left-16"
              >
                {context === contexts.booking ? (
                  <Link href="/" className={styles.burgerLogo}>
                    {isHandledDevice ? <StaticMobileLogo /> : <StaticLogo />}
                  </Link>
                ) : logo?.url ? (
                  <Link href="/" className={cn([styles.logoLink, styles.burgerLogo])}>
                    <div className={styles.logoContainer}>
                      <Image
                        fill
                        src={logo?.url}
                        alt={logo?.description}
                        className={styles.logo}
                        sizes="256px"
                      />
                    </div>
                  </Link>
                ) : null}

                {context === contexts.default ? (
                  <Navigation list={menu} type="tablet" />
                ) : null}

                {context === contexts.booking && !hideBookingBackToResidence
                && bookingResidence?.nameLong
                  ? (
                    <Link href="/" className="d-flex a-center p2-desktop-bold font-color-black margin-top-16">
                      <Picto icon="chevronLeft" className="margin-right-8" />
                      {bookingResidence?.nameLong}
                    </Link>
                  ) : null}

                <div className={styles.infoItems}>
                  {phone || context === contexts.booking ? (
                    <a
                      href={`tel:${context === contexts.booking ? BOOKING_PHONE_NUMBER[locale] : phone}`}
                      className={styles.phone}
                      aria-label={context === contexts.booking
                        ? BOOKING_PHONE_NUMBER[locale]
                        : phone}
                    >
                      {!isHandledDevice ? (
                        <Picto icon="call" className="margin-right-8" />
                      ) : null}
                      {context === contexts.booking ? BOOKING_PHONE_NUMBER[locale] : phone}
                    </a>
                  ) : null}

                  {context === contexts.booking ? (
                    <Link href={`/${CONCEPT_SLUG}`} className="p2-desktop-bold font-color-black">
                      {t('header.contactUs')}
                    </Link>
                  ) : null}

                  {context === contexts.default && accountLink && accountLabel ? (
                    <a href={accountLink} className="d-flex a-center gap-8 p2-desktop-bold font-color-black flex-auto-shrink">
                      <Picto icon="person" />
                      {accountLabel}
                    </a>
                  ) : null}

                  {isTranslatable ? (
                    <li className={styles.languageSelectorMobile}>
                      {[LOCALE_FR, LOCALE_EN].map((item) => {
                        const hasSlugItem = !isNull(slugs[item]);

                        return (item !== locale ? (
                          <Link
                            href={{
                              pathname: hasSlugItem ? pathname : null,
                              query: !isEmpty(slugs) && hasSlugItem
                                ? { slug: slugs[item] } : {},
                            }}
                            locale={hasSlugItem ? item : null}
                            key={item}
                            className={styles.languageMobile}
                            disabled={locale === item}
                            onClick={() => setMenuIsOpened(false)}
                          >
                            {t(`header.${item}`)}
                          </Link>
                        ) : (
                          <span key={item} className={cn([styles.languageMobile, styles.active, 'p2-desktop-bold font-color-black'])}>
                            {t(`header.${item}`)}
                          </span>
                        ));
                      })}
                    </li>
                  ) : null}
                </div>
              </BurgerMenu>
            ) : null}

            {context === contexts.booking && !isTablet ? (
              <>
                <Link href={`${CONTACT_SLUG}`} className="p2-desktop-bold font-color-black">
                  {t('header.contactUs')}
                </Link>
                <span className={styles.separator} />
              </>
            ) : null}

            {context === contexts.default && !isTablet ? (
              <>
                <div className={styles.separator} />
                {accountLink && accountLabel ? (
                  <a href={accountLink} className="d-flex a-center gap-8 p2-desktop-bold font-color-black flex-auto-shrink">
                    <Picto icon="person" />
                    {accountLabel}
                  </a>
                ) : null}
              </>
            ) : null}

            {(context === contexts.default || context === contexts.booking)
            && !isTablet
            && isTranslatable ? (
              <LanguageSelector slugs={slugs} />
              ) : null}
          </div>
        </div>
      </header>

      {context === contexts.booking && isHandledDevice && bookingResidence?.nameLong ? (
        <div className={cn([styles.residence, 'p2-desktop-bold'])}>
          {bookingResidence?.nameLong}
        </div>
      ) : null}
    </>

  );
}

Header.propTypes = {
  data: PropTypes.shape({
    accountLabel: PropTypes.string,
    accountLink: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    menu: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      }),
    })),
    phone: PropTypes.string,
    isTranslatable: PropTypes.bool,
  }),
  context: PropTypes.oneOf(Object.values(contexts)),
  sticky: PropTypes.bool,
  slugs: PropTypes.shape({}),
};

Header.defaultProps = {
  data: {
    accountLabel: '',
    accountLink: '',
    logo: {},
    menu: [],
    phone: '',
    isTranslatable: true,
  },
  context: 'default',
  sticky: true,
  slugs: {},
};

export default Header;
